import { Link } from "react-router-dom";

const NavPersonas = () => {
  return (
    <>
      <nav className="navbar navbar-expand nav-category z-index-9">
        <div className="container-sm mb-n3">
          {/* Logo START */}
          <a className="navbar-brand" href="/">
            <img
              className="light-mode-item navbar-brand-item"
              src="/assets/images/logo.png"
              alt="logo"
              style={{ width: "100px", height: "auto" }}
            />
          </a>
          {/* Logo END */}

          {/* Responsive navbar toggler */}
          {/*           <button
            className="navbar-toggler ms-auto btn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {" "}
            <span className="navbar-toggler-animation">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button> */}

          {/* Main navbar START */}
          <div className="navbar-collapse collapse" id="navbarCollapse">
            {/*    <div className="col-xxl-6">
              <div className="nav my-3 my-xl-0 px-4 flex-nowrap align-items-center">
                <div className="nav-item w-100"> */}
            {/* <form className="rounded position-relative">
                      <input
                        className="form-control pe-5 bg-secondary bg-opacity-10 border-0"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <button
                        className="btn btn-link bg-transparent px-2 py-0 position-absolute top-50 end-0 translate-middle-y"
                        type="submit"
                      >
                        <i className="fas fa-search fs-6 text-primary"></i>
                      </button>
                    </form> */}
            {/*    </div>
              </div>
            </div> */}
            {/* Nav Search END */}

            {/* Nav Main menu START */}
            <ul className="navbar-nav navbar-nav-scroll ms-auto">
              {/* Nav item 1 Demos */}
            </ul>
          </div>

          <ul className="nav flex-row  list-unstyled ms-xl-auto">
            {/* <li className="nav-item ms-0 ms-sm-2 d-none d-sm-block">
              <Link
                className="btn  text-white mb-0"
                to="/LoginAlumno"
                style={{ backgroundColor: "#009688" }}
              >
                Ingresar
              </Link>
            </li> */}
         {/*    <li className="nav-item  ms-sm-3 dropdown">
              <Link
                className="btn btn-sm text-white "
                to="/LoginAlumno"
                style={{ backgroundColor: "#009688" }}
              >
                <i className="fa-solid fa-right-to-bracket me-2"></i>
                Ingresar
              </Link>
            </li> */}
            <li className="nav-item ms-2 ms-sm-3 dropdown">
              <span className="notif-badge animation-blink"></span>

         <Link
                to="/RegistroAlumno"
                className="btn btn-sm"
                href="#"
                style={{
                  color: "#009688",
                  borderColor: "#009688",
                  backgroundColor: "transparent",
                  borderWidth: "2px",
                }}
              >
                Regístrate
              </Link> 
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavPersonas;
