import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import axios from "axios";
import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Normatividad = () => {
  const [normativas, setNormativas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}normatividad/datos`,
        {
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "Content-Type": "application/json",
            "X-Rol": `${localStorage.getItem("Rol")}`,
          },
        }
      );
      setNormativas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const sortNormativas = (a, b) => {
    const orderA = a.orden !== undefined ? a.orden : Number.MAX_SAFE_INTEGER;
    const orderB = b.orden !== undefined ? b.orden : Number.MAX_SAFE_INTEGER;

    if (orderA === 0) return 1;
    if (orderB === 0) return -1;
    if (orderA < orderB) return -1;
    if (orderA > orderB) return 1;
    return 0;
  };

  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();

  return (
    <>
      <Navbar />
      <section className="bg-light position-relative">
        <figure className="position-absolute bottom-0 start-0 d-none d-lg-block">
          <svg width="822.2px" height="301.9px" viewBox="0 0 822.2 301.9">
            <path
              className="fill-warning opacity-5"
              d="M752.5,51.9c-4.5,3.9-8.9,7.8-13.4,11.8c-51.5,45.3-104.8,92.2-171.7,101.4c-39.9,5.5-80.2-3.4-119.2-12.1 c-32.3-7.2-65.6-14.6-98.9-13.9c-66.5,1.3-128.9,35.2-175.7,64.6c-11.9,7.5-23.9,15.3-35.5,22.8c-40.5,26.4-82.5,53.8-128.4,70.7 c-2.1,0.8-4.2,1.5-6.2,2.2L0,301.9c3.3-1.1,6.7-2.3,10.2-3.5c46.1-17,88.1-44.4,128.7-70.9c11.6-7.6,23.6-15.4,35.4-22.8 c46.7-29.3,108.9-63.1,175.1-64.4c33.1-0.6,66.4,6.8,98.6,13.9c39.1,8.7,79.6,17.7,119.7,12.1C634.8,157,688.3,110,740,64.6 c4.5-3.9,9-7.9,13.4-11.8C773.8,35,797,16.4,822.2,1l-0.7-1C796.2,15.4,773,34,752.5,51.9z"
            ></path>
          </svg>
        </figure>{" "}
        <div className="container position-relative">
          <div className="row">
            <div className="col-12">
              <div className="row align-items-center">
                <figure className="position-absolute top-0 start-0">
                  <svg width="22px" height="22px" viewBox="0 0 22 22">
                    <polygon
                      className="fill-orange"
                      points="22,8.3 13.7,8.3 13.7,0 8.3,0 8.3,8.3 0,8.3 0,13.7 8.3,13.7 8.3,22 13.7,22 13.7,13.7 22,13.7 "
                    ></polygon>
                  </svg>
                </figure>

                <div className="col-6 col-md-3 text-center order-1">
                  <img src="/assets/images/stemisp/Mesa1.svg" alt="" />
                </div>
                <div className="col-md-6 px-md-5 text-center position-relative order-md-2 mb-5 mb-md-0">
                  <figure className="position-absolute top-0 start-50 translate-middle mt-n6 d-none d-md-block">
                    <svg width="27px" height="27px">
                      <path
                        className="fill-purple"
                        d="M13.122,5.946 L17.679,-0.001 L17.404,7.528 L24.661,5.946 L19.683,11.533 L26.244,15.056 L18.891,16.089 L21.686,23.068 L15.400,19.062 L13.122,26.232 L10.843,19.062 L4.557,23.068 L7.352,16.089 L-0.000,15.056 L6.561,11.533 L1.582,5.946 L8.839,7.528 L8.565,-0.001 L13.122,5.946 Z"
                      ></path>
                    </svg>
                  </figure>
                  <h1 className="mb-3">Normativa actualizada</h1>
                  <p className="mb-3">
                    Actualizamos las normativas más importantes todos los meses
                  </p>
                </div>
                <div className="col-6 col-md-3 text-center order-3">
                  <img
                    src="/assets/images/stemisp/Mesa2.svg"
                    className="w-100 h-100"
                    alt=""
                  />
                </div>
              </div>{" "}
            </div>
          </div>{" "}
        </div>
      </section>

      <section className="container mt-5">
        {isLoading ? (
          <div className="text-center">
            <img src="/assets/images/stemisp/gatitos.gif" alt="Cargando..." />
            <p>Cargando normativas...</p>
          </div>
        ) : (
          <div className="row row-cols-1 row-cols-md-4 g-4">
            {normativas.sort(sortNormativas).map((normativa) => (
              <div key={normativa.id} className="col">
                <div className="card">
                  <Link to={`/Normadetalles/${normativa.slug}`}>
                    <img
                      src={`${window.BACKEND_URL}uploads/${normativa.foto_card}`}
                      className="card-img-top rounded-3"
                      alt={normativa.titulo}
                      style={{
                        width: "100%",
                        height: "230px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="mt-2">
                      <h5 className="card-title">{normativa.titulo}</h5>
                      <p className="card-text mt-n2">
                        Actualizado a {month} de {year}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};
export default Normatividad;
