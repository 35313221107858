import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const CursosPage = () => {
  const [curso, setCurso] = useState(null);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const [showPrices, setShowPrices] = useState(true);

  const createMarkup = () => {
    return { __html: curso.detalles };
  };

  const fetchCursoBySlug = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}curso/slug/${slug}`
      );
      setCurso(response.data);
    } catch (error) {
      console.error("Error fetching curso by slug:", error);
    } finally {
      setLoading(false);
    }
  };

  const copyLinkToClipboard = () => {
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = window.location.href;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  useEffect(() => {
    const fetchCursoBySlug = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}curso/slug/${slug}`,
          {
            headers: {
              Authorization: `${localStorage.getItem("TokenPublic")}`,
              "Content-Type": "application/json",
              "X-Rol": `${localStorage.getItem("Rol")}`,
            },
          }
        );
        setCurso(response.data);
      } catch (error) {
        console.error("Error fetching curso by slug:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCursoBySlug();
  }, [slug]);

  const renderDificultad = (nivel) => {
    let texto;

    switch (nivel) {
      case 1:
        texto = "Básico";
        break;
      case 2:
      case 3:
        texto = "Intermedio";
        break;
      case 4:
      case 5:
        texto = "Avanzado";
        break;
      default:
        texto = "Desconocido";
    }

    return <>{texto}</>;
  };

  const renderPrecioList = () => {
    const labels = {
      alumnos: "Alumnos",
      egresadosYBachiller: "Egresados y Bachilleres",
      abogadosTitulados: "Abogados Titulados",
      agremiadosCAP: "Agremiados del Colegio de Abogados de Puno",
      grupoDe3: "Grupos de 3 personas",
      grupoDe4: "Grupos de 4 personas",
    };

    const secciones = [
      {
        title: "Costo del curso",
        fields: [
          "alumnos",
          "egresadosYBachiller",
          "abogadosTitulados",
          "agremiadosCAP",
        ],
      },
      { title: "Precios corporativos", fields: ["grupoDe3", "grupoDe4"] },
    ];

    return (
      <div>
        {secciones.map((seccion) => (
          <div key={seccion.title} className="mb-3">
            <h4>{seccion.title}</h4>
            <ul>
              {seccion.fields.map((campo) => (
                <li key={campo}>
                  {labels[campo]}: S/{curso.precio[campo]}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Navbar />
      {loading ? (
        <div className="text-center">
          <img src="/assets/images/stemisp/gatitos.gif" alt="Cargando..." />
          <p>Cargando curso...</p>
        </div>
      ) : curso ? (
        <section className="pt-3 pt-xl-5">
          <div className="container" data-sticky-container>
            <div className="row g-4">
              <div className="col-xl-8">
                <div className="row g-4">
                  <div className="col-12">
                    <h2>{curso.nombre}</h2>
                    <p>{curso.descripcion}</p>
                  </div>

                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators">
                      {/* Botón del indicador para la imagen de banerimg */}
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 0"
                      ></button>
                      {/* Botones de indicadores para las imágenes del campo baner */}
                      {curso.baner?.map((item, index) => (
                        <button
                          type="button"
                          key={index}
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={index + 1} // índice ajustado
                          className=""
                          aria-label={`Slide ${index + 1}`}
                        ></button>
                      ))}
                    </div>
                    <div className="carousel-inner">
                      {/* Imagen de banerimg */}
                      <div className="carousel-item active">
                        <img
                          src={`${window.BACKEND_URL}uploads/${curso.banerimg}`}
                          className="d-block w-100"
                          alt="Card image"
                        />
                      </div>
                      {/* Imágenes del campo baner */}
                      {curso.baner?.map((item, index) => (
                        <div className="carousel-item" key={item._id}>
                          <img
                            src={`${window.BACKEND_URL}uploads/${item.photo}`}
                            className="d-block w-100"
                            alt={`Slide ${index + 1}`}
                          />
                        </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>

                  <div className="col-12">
                    <div className="card border">
                      <div className="card-header border-bottom">
                        <h3 className="mb-0">Descripcion del curso</h3>
                      </div>
                      <div className="card-body">
                        <div
                          className="mb-0 pt-0"
                          style={{ color: "#464D5B", textAlign: "justify" }}
                          dangerouslySetInnerHTML={createMarkup()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card border rounded-3">
                      {/* Aquí podrías mostrar preguntas frecuentes u otro contenido relacionado con el curso */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div data-sticky data-margin-top="80" data-sticky-for="768">
                  <div className="row g-4">
                    <div className="col-md-6 col-xl-12">
                      <div className="card card-body border p-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>{showPrices && renderPrecioList()}</div>
                          <div className="dropdown">
                            <a
                              className="btn btn-sm btn-light rounded mb-0 small"
                              role="button"
                              id="dropdownShare"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fas fa-fw fa-share-alt"></i>
                            </a>
                            <ul
                              className="dropdown-menu dropdown-w-sm dropdown-menu-end min-w-auto shadow rounded"
                              aria-labelledby="dropdownShare"
                            >
                              <li>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="dropdown-item"
                                  href="https://www.facebook.com/people/STemis/100087944859853/"
                                >
                                  <i className="fab fa-facebook-square me-2"></i>
                                  Facebook
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={copyLinkToClipboard}
                                >
                                  <i className="fas fa-copy me-2"></i>Copy link
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <hr />
                        <h5 className="mb-3">Detalles del curso </h5>
                        <ul className="list-group list-group-borderless border-0">
                          <li className="list-group-item px-0 d-flex justify-content-between">
                            <span className="h6 fw-light mb-0">
                              <i className="fas fa-fw fa-clock text-danger"></i>
                              Duración
                            </span>
                            <span>{curso.dias}</span>
                          </li>
                          <li className="list-group-item px-0 d-flex justify-content-between">
                            <span className="h6 fw-light mb-0">
                              <i className="fas fa-fw fa-signal text-warning"></i>
                              Nivel
                            </span>
                            <span> {renderDificultad(curso.dificultad)}</span>
                          </li>
                          <li className="list-group-item px-0 d-flex justify-content-between">
                            <span className="h6 fw-light mb-0">
                              <i className="fas fa-fw fa-user-clock text-primary"></i>
                              Inicio
                            </span>
                            <span>
                              {new Date(curso.fecha_in).toLocaleDateString(
                                "es-ES",
                                { day: "numeric", month: "long" }
                              )}
                            </span>
                          </li>
                          <li className="list-group-item px-0 d-flex justify-content-between">
                            <span className="h6 fw-light mb-0">
                              <i className="fas fa-fw fa-medal text-success civil"></i>
                              Certificado
                            </span>
                            <span>
                              Ilustre Colegio de <br /> Abogados de Puno
                            </span>
                          </li>
                        </ul>
                        <hr />
                        <div className="d-sm-flex justify-content-sm-between align-items-center mt-0 mt-sm-2">
                          <Link
                            to={`/pre-incripcion/${curso._id}`}
                            className="btn btn-sm btn-outline-success mb-0 mt-2 mt-sm-0"
                          >
                            Pre Inscripcion
                          </Link>
                          <div className="d-flex gap-2 mt-2 mt-sm-0">
                            <a
                              href={`${window.BACKEND_URL}uploads/${curso.brochure}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-sm btn-info"
                            >
                              <i className="bi bi-file-earmark-arrow-down"></i>{" "}
                              Más información
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-12">
                      <div className="card card-body border p-4">
                        <h4 className="mb-3">Materia</h4>
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <a
                              disabled
                              className="btn Civil btn-sm thext-wt"
                              style={{
                                color: "white",
                                border: "1px solid transparent",
                                transition: "border-color 0.3s, color 0.3s",
                                cursor: "auto",
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.border = "1px solid green";
                                e.target.style.color = "green";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.border = "1px solid transparent";
                                e.target.style.color = "white";
                              }}
                            >
                              {curso.materia}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <p>Curso no encontrado</p>
      )}
      <Footer />
    </>
  );
};

export default CursosPage;
