import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Modal from "react-modal";
const RegistroAlumno = () => {
  const [alumno, setAlumno] = useState({
    Nombre: "",
    Correo: "",
    Dni: "",
    Celular: "",
    Contrasena: "",
    validarEmail: "",
    Terminos: false,
    Autorizacion: false,
    Declaracion: false,
  });
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const [terminos, setTerminos] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setAlumno((prevAlumno) => ({
      ...prevAlumno,
      [name]: value,
    }));
  };

  const getTerminos = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}terminos/condiciones`,
        {
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "Content-Type": "application/json",
            "X-Rol": `${localStorage.getItem("Rol")}`,
          },
        }
      );
      setTerminos(response.data);
    } catch (error) {
      console.error("Error al obtener los terminos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${window.BACKEND_URL}alumno/registrate`,
        alumno,
        {
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "Content-Type": "application/json",
            "X-Rol": `${localStorage.getItem("Rol")}`,
          },
        }
      );
      if (response.status === 201) {
        setRegistroExitoso(true);
        Cookies.set("alumnoData", JSON.stringify(alumno));
        Swal.fire(
          "Registro Exitoso",
          "Usuario registrado con éxito. Revisa tu correo electrónico para completar el registro.",
          "success"
        );
      }
    } catch (error) {
      console.error("Error al registrar al alumno:", error);
      if (error.response && error.response.status === 400) {
        Swal.fire("Error", "El Usuario ya existe .", "error");
      } else {
        Swal.fire(
          "Error",
          "Error al registrar al alumno. Por favor, inténtalo de nuevo más tarde.",
          "error"
        );
      }
    } finally {
      setLoading(false); // Establecer el estado de carga a false al finalizar la solicitud
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setAlumno({
      ...alumno,
      [name]: checked,
    });
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <>
      <section className="p-0 d-flex align-items-center position-relative overflow-hidden">
        <div className="container-fluid">
          <div className="row">
            {/* Left */}
            <div className="col-12 col-lg-6 d-md-flex align-items-center justify-content-center bg-white bg-opacity-10 vh-lg-100">
              <img
                src="/assets/images/registro/registro.svg"
                alt="Éxito"
                className="me-3"
              />
            </div>

            {/* Right */}
            <div className="col-12 col-lg-6 m-auto">
              <div className="row my-5">
                <div className="col-sm-10 col-xl-8 m-auto">
                  <h2>Registro de Participante </h2>
                  <p className="lead mb-4">Forma parte de Stemis </p>
                  {registroExitoso ? (
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/images/logo.png"
                        alt="Éxito"
                        className="me-3"
                        style={{ maxWidth: "50px" }}
                      />
                      <p>
                        Usuario registrado con éxito. Revisa tu correo
                        electrónico para completar el registro.
                      </p>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit} className="row g-3">
                      <input
                        className="form-control border-0 bg-light rounded-end ps-1"
                        placeholder="Nombre"
                        type="hidden"
                        id="Rol"
                        name="Rol"
                        value="1"
                      />
                      {/* Nombre y Apellidos */}
                      <div className="col-md-12">
                        <label htmlFor="nombre" className="form-label">
                          Nombre :
                        </label>
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="fa-solid fa-signature"></i>{" "}
                          </span>
                          <input
                            className="form-control border-0 bg-light rounded-end ps-1"
                            placeholder="Nombre completo (Nombre y Apelldios)"
                            type="text"
                            id="Nombre"
                            name="Nombre"
                            value={alumno.Nombre}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="email" className="form-label">
                          Email :
                        </label>
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="bi bi-envelope-fill"></i>
                          </span>
                          <input
                            className="form-control border-0 bg-light rounded-end ps-1"
                            placeholder="email"
                            type="email"
                            id="Correo"
                            name="Correo"
                            value={alumno.Correo}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="validarEmail" className="form-label">
                          Validar Email :
                        </label>
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="bi bi-envelope-fill"></i>
                          </span>
                          <input
                            className="form-control border-0 bg-light rounded-end ps-1"
                            placeholder="Validar email"
                            type="email"
                            id="validarEmail"
                            name="validarEmail"
                            value={alumno.validarEmail}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      {/* DNI */}
                      <div className="col-12">
                        <label htmlFor="dni" className="form-label">
                          DNI :
                        </label>
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="fa-regular fa-address-card"></i>
                          </span>
                          <input
                            className="form-control border-0 bg-light rounded-end ps-1"
                            placeholder="DNI"
                            type="text"
                            id="Dni"
                            name="Dni"
                            value={alumno.Dni}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <label htmlFor="dni" className="form-label">
                          Celular :
                        </label>
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="fa-solid fa-mobile-screen"></i>
                          </span>
                          <input
                            className="form-control border-0 bg-light rounded-end ps-1"
                            placeholder="Celular"
                            type="text"
                            id="Celular"
                            name="Celular"
                            value={alumno.Celular}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      {/* Contraseña */}
                      <div className="col-12">
                        <label htmlFor="password" className="form-label">
                          Contraseña *
                        </label>
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="fas fa-lock"></i>
                          </span>
                          <input
                            type="password"
                            className="form-control border-0 bg-light rounded-end ps-1"
                            placeholder="*********"
                            id="Contrasena"
                            name="Contrasena"
                            value={alumno.Contrasena}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="mb-1 ms-lg-2 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Terminos"
                          name="Terminos"
                          checked={alumno.Terminos}
                          onChange={handleCheckboxChange}
                          required
                        />
                        <label className="form-check-label" htmlFor="Terminos">
                          Autoriza que se le envié información sobre desarrollo
                          de cursos, concursos, webinars organizados por Stemis
                          y sus aliados estratégicos{" "}
                          <span
                            onClick={openModal}
                            style={{
                              textDecoration: "underline",
                            }}
                            className="text-primary"
                          >
                            Términos y Condiciones
                          </span>
                        </label>
                      </div>
                      <div className="mb-1 ms-ls-2 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Autorizacion"
                          name="Autorizacion"
                          checked={alumno.Autorizacion}
                          onChange={handleCheckboxChange}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Autorizacion"
                        >
                          La información proporcionada respecto a sus datos
                          ingresados, así como el correo proporcionado, están
                          correctamente redactados a efecto de generar una
                          futura emisión de certificados con dicha información
                        </label>
                      </div>
                      <div className="mb-1 ms-lg-2 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Declaracion"
                          name="Declaracion"
                          checked={alumno.Declaracion}
                          onChange={handleCheckboxChange}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Declaracion"
                        >
                          Para efectos de la presente inscripción se tendrá en
                          cuenta la información proporcionada como cierta con
                          efecto de declaración jurada
                        </label>
                      </div>

                      {/* Botón de registro */}
                      <div className="col-12">
                        <div className="d-grid">
                          <button
                            className="btn btn-outline-success mb-0"
                            type="submit"
                            disabled={loading} // Deshabilita el botón mientras se carga
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">
                                  Cargando...
                                </span>
                              </>
                            ) : (
                              <>Registrarme</>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={getTerminos}
        onRequestClose={closeModal}
        contentLabel="Términos y Condiciones"
        ariaHideApp={false}
      >
        {terminos.map((termino) => (
          <div key={termino._id}>
            <div className="container mx-4">
              <div
                className="mb-0 "
                style={{ color: "#464D5B", textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: termino.Termino,
                }}
              ></div>
            </div>
          </div>
        ))}
        <button className="btn btn-outline-secondary" onClick={closeModal}>
          Cerrar
        </button>
      </Modal>
    </>
  );
};

export default RegistroAlumno;
