import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import CursoHeader from "./CursoHeader";
import CursoInfo from "./CursoInfo";
import SesionAccordion from "./SesionAccordion";

const AulaDetalles = () => {
  const { slug } = useParams();
  const [curso, setCurso] = useState(null);
  const [docente, setDocente] = useState(null);
  const [sesion, setSesiones] = useState(null);

  useEffect(() => {
    const fetchCurso = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}curso/slug/${slug}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
              "X-Rol": `${sessionStorage.getItem("role")}`,
            },
          }
        );
        setCurso(response.data);
        const curso_id = response.data._id;

        const getSesiones = async () => {
          try {
            const response = await axios.get(
              `${window.BACKEND_URL}curso/sesion/${curso_id}/aula/virtual`,
              {
                headers: {
                  Authorization: `${sessionStorage.getItem("token")}`,
                  "Content-Type": "application/json",
                  "X-Rol": `${sessionStorage.getItem("role")}`,
                },
              }
            );
            setSesiones(response.data);
          } catch (error) {
            console.error("Error fetching curso by slug:", error);
          }
        };
        getSesiones();

        const docente_id = response.data.docente_id;
        const docenteResponse = await axios.get(
          `${window.BACKEND_URL}users/${docente_id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
              "X-Rol": `${sessionStorage.getItem("role")}`,
            },
          }
        );
        setDocente(docenteResponse.data);
      } catch (error) {
        console.error("Error fetching curso:", error);
      }
    };

    fetchCurso();
  }, [slug]);

  const renderDificultad = (nivel) => {
    let icono;
    let texto;

    switch (nivel) {
      case 1:
        icono = <i className="fas fa-star text-warning"></i>;
        texto = "Básico";
        break;
      case 2:
      case 3:
        icono = <i className="fas fa-star text-warning"></i>;
        texto = "Intermedio";
        break;
      case 4:
      case 5:
        icono = <i className="fas fa-star text-warning"></i>;
        texto = "Avanzado";
        break;
      default:
        icono = <i className="fas fa-star text-warning"></i>;
        texto = "Desconocido";
    }

    return (
      <>
        {icono} {texto}
      </>
    );
  };

  if (!curso || !docente) {
    return (
      <div>
        <Navbar />
        <div className="d-flex justify-content-center align-items-center m-7">
          <div className="text-center">
            <div className="loader"></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `url('/assets/images/aula/fondoaula.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <CursoHeader
          curso={curso}
          docente={docente}
          renderDificultad={renderDificultad}
        />
        <CursoInfo sesion={sesion} />
        <section className="pt-0">
          <div className="container">
            {sesion?.sesionModulos.map((modulo, index) => (
              <SesionAccordion
                key={index}
                titulo={modulo.titulo}
                numeroModulo={index + 1}
                docente={modulo.docente}
                content={modulo.contenido}
                file1={modulo.file1}
                file2={modulo.file2}
                youtubeLink={modulo.youtubeLink || ""}
                tipo={modulo.tipo || ""}
                adicional={modulo.adicional || []}
              />
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AulaDetalles;
