import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";

// Función para generar y almacenar el token público
const generatePublicToken = async () => {
  try {
    const response = await axios.get(
      `${window.BACKEND_URL}usuario/publico/token`
    );
    const { token } = response.data;
    localStorage.setItem("TokenPublic", token);
    localStorage.setItem("Rol", "publicUser");
  } catch (error) {
    console.error("Error al generar y almacenar el token público:", error);
  }
};

// Llamada a la función para generar el token público antes de renderizar la aplicación
generatePublicToken().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
});

// Si deseas medir el rendimiento de tu aplicación, puedes descomentar la siguiente línea
// Más información: https://bit.ly/CRA-vitals
reportWebVitals();
