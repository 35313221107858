import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import React, { useEffect } from "react";

const SimuladorHome = () => {
  useEffect(() => {
    // Recarga la página al entrar en la vista
    window.scrollTo(0, 0); // Restablece el desplazamiento a la parte superior
  }, []);
  return (
    <>
      <Navbar />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-4 ms-3">
              <h1 className="fs-1">
                Experimenta una <br />
                <span style={{ color: "#009688" }}> nueva </span>
                forma de
                <span style={{ color: "#009688" }}> aprender.</span>
              </h1>
              <p className="mt-3 text-indigo">
                sTemis es una solución digital y académica en el ámbito legal.
                Brinda un espacio práctico que permite al alumno univesitario
                aprender cómo se desarrolla un proceso judicial a través de su
                simulación.
              </p>
              <div className="d-flex justify-content-center mt-4">
                <a
                  className="btn"
                  style={{ backgroundColor: "#259995", color: "white" }}
                  href="https://simulador.stemis.pe/"
                >
                  Ingresa aquí
                </a>
              </div>
            </div>
            <div className="col-md-7">
              <div style={{ maxWidth: "750px", height: "420px" }}>
                <iframe
                  className="w-100 h-100"
                  style={{ maxWidth: "750px", height: "420px" }}
                  data-aos-delay="1200"
                  src="https://www.youtube.com/embed/vzNv7P_gdAM"
                  title="YouTube video player"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="row g-lg-5 mt-3 ">
          {/* Item */}
          <div className="col-md-6 col-lg-4 text-center position-relative">
            <figure className="position-absolute top-0 start-100 translate-middle d-none d-lg-block">
              <svg width="182.9px" height="86px" viewBox="0 0 182.9 86">
                <path
                  className="fill-secondary"
                  d="M127.3,19.8c0.9,0.7,1.8,1.5,2.8,2c9.3,4.5,17.1,11.1,24.4,18.3c6.5,6.4,11.9,13.7,15.8,22 c1.5,3.2,2.7,6.6,4.2,10.2c2.5-4.2,4.1-8.9,8.6-11.5c-2.2,3.9-4.7,7.7-6.5,11.9c-1.7,4.1-2.6,8.6-3.9,13.4 c-4.1-6.1-7-13.2-14.9-15.6c3.8-1.4,6.2,0.5,14.1,10.3c1-2.2,1.8-4.1,1.1-6.5c-3.8-13.6-11.4-24.8-21.4-34.6 c-5.8-5.7-12.3-10.6-19.2-14.9c-7-4.3-14.4-8-22.2-10.9c-10.7-3.8-21.5-6.6-32.8-7.7C63.9,5,50.7,5.9,38,10.4 c-14.1,5-26,13.2-35,25.4c-0.5,0.7-1.2,1.4-1.8,2.1C1.1,38,0.8,38,0.4,38.1c-0.9-0.9-0.2-1.7,0.3-2.4c4.7-6.7,10.5-12.4,17.2-17.1 C31.7,8.9,47.2,4.7,63.8,4C77.1,3.5,90,5.8,102.7,9.3c2.2,0.6,4.3,1.8,6.5,2.6c0.9,0.4,2,0.5,2.9,0.7 C117.1,15,122.2,17.4,127.3,19.8z"
                />
              </svg>
            </figure>
            <div className="px-4">
              {/* Image */}
              <div className="icon-xxl bg-body shadow mx-auto rounded-circle mb-3">
                <img src="/assets/images/simulado/simulador1.svg" alt="Child" />
              </div>
              {/* Title */}
              <h5>El docente crea la casuísticas</h5>
              <div className="text-center">
                <p className="text-truncate-2 px-5">
                  Presentando cauísticas en diversas materias del derecho{" "}
                </p>
              </div>
            </div>
          </div>

          {/* Item */}
          <div className="col-md-6 col-lg-4 text-center pt-0 pt-lg-5 position-relative">
            {/* SVG decoration */}
            <figure className="position-absolute top-100 start-100 translate-middle mt-n3 d-none d-lg-block">
              <svg width="182.9px" height="86px" viewBox="0 0 182.9 86">
                <path
                  className="fill-secondary"
                  d="M127.3,70.2c0.9-0.7,1.8-1.5,2.8-2c9.3-4.5,17.1-11.1,24.4-18.3c6.5-6.4,11.9-13.7,15.8-22 c1.5-3.2,2.7-6.6,4.2-10.2c2.5,4.2,4.1,8.9,8.6,11.5c-2.2-3.9-4.7-7.7-6.5-11.9c-1.7-4.1-2.6-8.6-3.9-13.4 c-4.1,6.1-7,13.2-14.9,15.6c3.8,1.4,6.2-0.5,14.1-10.3c1,2.2,1.8,4.1,1.1,6.5c-3.8,13.6-11.4,24.8-21.4,34.6 c-5.8,5.7-12.3,10.6-19.2,14.9c-7,4.3-14.4,8-22.2,10.9c-10.7,3.8-21.5,6.6-32.8,7.7C63.9,85,50.7,84.1,38,79.6 c-14.1-5-26-13.2-35-25.4c-0.5-0.7-1.2-1.4-1.8-2.1c-0.1-0.1-0.4-0.1-0.8-0.2c-0.9,0.9-0.2,1.7,0.3,2.4c4.7,6.7,10.5,12.4,17.2,17.1 c13.7,9.7,29.2,14,45.9,14.6c13.3,0.5,26.2-1.8,38.8-5.3c2.2-0.6,4.3-1.8,6.5-2.6c0.9-0.4,2-0.5,2.9-0.7 C117.1,74.9,122.2,72.6,127.3,70.2z"
                />
              </svg>
            </figure>
            <div className="px-4">
              {/* Image */}
              <div className="icon-xxl bg-body shadow mx-auto rounded-circle mb-3">
                <img src="assets/images/simulado/simulador2.svg" alt="Idea" />
              </div>
              {/* Title */}
              <h5>Asigna roles a los alumnos</h5>
              <p className="text-center px-5">
                Simulando ser sujetos procesales: parte demandante, demandada,
                juez y abogados defensores
              </p>
            </div>
          </div>

          {/* Item */}
          <div className="col-md-6 col-lg-4 text-center">
            <div className="px-4">
              {/* Image */}
              <div className="icon-xxl bg-body shadow mx-auto rounded-circle mb-6">
                <img src="assets/images/simulado/simulador3.svg" alt="Help" />
              </div>
              {/* Title */}
              <h5>Desarrolla el caso</h5>
              <p className="text-enter px-5 ">
                Desde la presentación de la demanda hasta la expedición de
                sentencia
              </p>
            </div>
          </div>
        </div>
      </section>
      5
      <section className="mt-5 pb-4 py-md-4" style={{ color: "#464D5B" }}>
        <div className="container">
          <div
            className="text-center align-items-center justify-content-center aos-init aos-animate"
            data-aos="fade-down"
          >
            <h1>
              TODO LO QUE PODEMOS{" "}
              <span style={{ color: "#259995" }}>HACER</span>
            </h1>
            <br />
          </div>

          <div className="row">
            <div className="col  mt-8 ">
              <div className="row" style={{ fontSize: "large" }}>
                <div
                  className="col-12 col-mb-6 py-2 aos-init aos-animate mz-3 "
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <img
                    style={{
                      width: "3%",
                      paddingBottom: "0.5%",
                      marginRight: "10px",
                    }}
                    src="/assets/images/simulado/cuadradito verde.png"
                    alt="Cuadradito verde"
                  />
                  Creación de expedientes electrónicos.
                </div>
                <div
                  className="col-12 py-2 aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <img
                    style={{
                      width: "3%",
                      paddingBottom: "0.5%",
                      marginRight: "10px",
                    }}
                    src="/assets/images/simulado/cuadradito plomo.png"
                    alt="Cuadradito plomo"
                  />
                  Presentación de escritos y notificaciones por las partes del
                  proceso.
                </div>
                <div
                  className="col-12 py-2 aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-delay="400"
                >
                  <img
                    style={{
                      width: "3%",
                      paddingBottom: "0.5%",
                      marginRight: "10px",
                    }}
                    src="/assets/images/simulado/cuadradito celeste clarito.png"
                    alt="Cuadradito celeste clarito"
                  />
                  Calificar, guardar y editar notas en el libro de
                  calificaciones.
                </div>
                <div
                  className="col-12 py-2 aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-delay="600"
                >
                  <img
                    style={{
                      width: "3%",
                      paddingBottom: "0.5%",
                      marginRight: "10px",
                    }}
                    src="/assets/images/simulado/cuadradito verde.png"
                    alt="Cuadradito verde"
                  />
                  Conexión via Zoom y Google Meet para audiencias virtuales.
                </div>
                <div
                  className="col-12 py-2 aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-delay="800"
                >
                  <img
                    style={{
                      width: "3%",
                      paddingBottom: "0.5%",
                      marginRight: "10px",
                    }}
                    src="/assets/images/simulado/cuadradito plomo.png"
                    alt="Cuadradito plomo"
                  />
                  Creación de cuadernos por cada expediente.
                </div>
                <div
                  className="col-12 py-2 aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-delay="1000"
                >
                  <img
                    style={{
                      width: "3%",
                      paddingBottom: "0.5%",
                      marginRight: "10px",
                    }}
                    src="/assets/images/simulado/cuadradito celeste clarito.png"
                    alt="Cuadradito celeste clarito"
                  />
                  Asignación de etapas procesales
                </div>
              </div>
            </div>
            <div
              className="col-md-6 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-delay="1200"
            >
              <img
                className="img-fluid"
                src="/assets/images/simulado/expediente.png"
                alt="Expediente"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SimuladorHome;
