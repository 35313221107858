import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavPersonas from "./NavPersonas";
import NavAlumnos from "./NavAlumno";

const Navbar = () => {
  const [usuarioAutenticado, setUsuarioAutenticado] = useState(false);
  const userName = sessionStorage.getItem("userName");

  useEffect(() => {
    /*     console.log("del navbar el session", sessionStorage);
    console.log(userName); */

    if (userName) {
      setUsuarioAutenticado(true);
    } else {
      setUsuarioAutenticado(false);
    }
  }, [userName]);

  return (
    <>
      <header>
        {usuarioAutenticado ? <NavAlumnos /> : <NavPersonas />}

        <nav className="navbar navbar-expand-xl nav-category">
          <div className="container py-0">
            <button
              className="navbar-toggler m-auto w-100 btn btn-success bg-stemis"
              type="btn"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse2"
              aria-controls="navbarCollapse2"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="bi bi-grid-fill bg-stemis"></i> Categorias
            </button>

            <div
              className="navbar-collapse w-100 collapse"
              id="navbarCollapse2"
            >
              <ul className="navbar-nav navbar-nav-scroll mx-auto">
                {/*  <li className="nav-item">
                  <a className="nav-link" href="#" style={{ color: "#009688" }}>
                    Capacitaciones
                  </a>
                </li> */}
                <li className="nav-item dropdown">
                  <Link
                    to="/normatividad"
                    className="nav-link"
                    style={{ color: "#009688" }}
                  >
                    Normativa
                  </Link>
                </li>
                {/*    <li className="nav-item">
                  <a className="nav-link" href="#" style={{ color: "#009688" }}>
                    Concursos
                  </a>
                </li> */}

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/SimuladorHome"
                    style={{ color: "#009688" }}
                  >
                    Simulador
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/blogs"
                    className="nav-link"
                    style={{ color: "#009688" }}
                  >
                    Blog
                  </Link>
                </li>

                {/*  <li className="nav-item">
                    <a className="nav-link"" style={{ color: "#009688" }}>
                      Videos
                    </a>
                  </li> */}

                <li className="nav-item">
                  <Link
                    to="/CursosHome"
                    className="nav-link"
                    style={{ color: "#009688" }}
                  >
                    Cursos/Webinar
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/Contact"
                    style={{ color: "#009688" }}
                  >
                    Contactanos
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/Nosotros-stemis"
                    style={{ color: "#009688" }}
                  >
                    Nosotros
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/Concursos"
                    style={{ color: "#009688" }}
                    dia
                  >
                    Concursos
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
