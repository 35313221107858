import { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../components/Footer";

const Editpassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alumnoId, setAlumnoId] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  useEffect(() => {
    // Obtener el ID del alumno de sessionStorage al cargar el componente
    const id = sessionStorage.getItem("alumnoId");
    if (id) {
      setAlumnoId(id);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }

    try {
      // Enviar la nueva contraseña al servidor
      const response = await axios.put(
        `${window.BACKEND_URL}alumno/${alumnoId}/password`,
        {
          password,
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "Content-Type": "application/json",
            "X-Rol": `${localStorage.getItem("Rol")}`,
          },
        }
      );
      alert("Contraseña actualizada exitosamente");

      // Volver a la página anterior
      window.history.back();
      // Mostrar algún mensaje de éxito al usuario si es necesario
    } catch (error) {
      console.error("Error al guardar la contraseña:", error);
      // Mostrar un mensaje de error al usuario si la solicitud falla
    }
  };
  const handleCancel = () => {
    window.history.back();
  };
  return (
    <>
      <section className="p-0 d-flex align-items-center position-relative overflow-hidden">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-6 d-md-flex align-items-center justify-content-center bg-primary bg-opacity-10 vh-lg-100">
              <div className="p-3 p-lg-5 d-flex flex-column align-items-center">
                <img
                  src="/assets/images/logostemis/contraseña.svg"
                  className="mt-5"
                  alt=""
                  style={{ maxWidth: "500px", width: "180%", height: "auto" }}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center">
              <div className="row my-5">
                <div className="col-sm-10 col-xl-12 m-auto">
                  <h1 className="fs-2">Edita tu contraseña</h1>

                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Nueva contraseña *
                      </label>
                      <div className="input-group input-group-lg">
                        <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                          <i className="fas fa-lock"></i>
                        </span>
                        <input
                          type="password"
                          className="form-control border-0 bg-light rounded-end ps-1"
                          placeholder="Contraseña nueva"
                          id="exampleInputPassword1"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="exampleInputPassword2"
                        className="form-label"
                      >
                        Confirmar contraseña *
                      </label>
                      <div className="input-group input-group-lg">
                        <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                          <i className="fas fa-lock"></i>
                        </span>
                        <input
                          type="password"
                          className={`form-control border-0 bg-light rounded-end ps-1 ${
                            !passwordsMatch ? "is-invalid" : ""
                          }`}
                          placeholder="Confirmar contraseña"
                          id="exampleInputPassword2"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setPasswordsMatch(true);
                          }}
                        />
                        {!passwordsMatch && (
                          <div className="invalid-feedback">
                            Las contraseñas no coinciden
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col">
                        <button
                          className="btn btn-secondary mb-0"
                          type="button"
                          onClick={handleCancel}
                        >
                          Cancelar
                        </button>
                      </div>
                      <div className="col">
                        <button className="btn btn-success mb-0" type="submit">
                          Guardar contraseña
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Editpassword;
