import React from "react";

const CursoHeader = ({ curso, docente, renderDificultad }) => (
  <section className="bg-success bg-opacity-100 py-2">
    <div className="container-sm">
      <div className="row justify-content-lg-between">
        <div className="col-lg-8">
          <h1 className="text-white">{curso.nombre}</h1>
          <h6 className="text-white">{curso.materia}</h6>
          <p className="text-white">{curso.descripcion}</p>
          <ul className="list-inline mb-5">
            <li className="list-inline-item h6 me-4 mb-1 mb-sm-0 text-white">
              <span className="fw-light">Docente:</span> {docente.name}
            </li>
            <li className="list-inline-item h6 mb-0 text-white">
              {renderDificultad(curso.dificultad)}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default CursoHeader;
