import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const WebinarPage = () => {
  const [webinar, setWebinar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { slug } = useParams();
  const descripcionRef = useRef(null);

  const fetchCursoBySlug = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}Webinarios/slug/${slug}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "Content-Type": "application/json",
            "X-Rol": `${localStorage.getItem("Rol")}`,
          },
        }
      );

      setWebinar(response.data);
    } catch (error) {
      console.error("Error fetching curso by slug:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCursoBySlug();
  }, [slug]);

  const extractYouTubeVideoID = (url) => {
    if (!url) return null;
    const regex =
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const videoId =
    webinar && webinar.link ? extractYouTubeVideoID(webinar.link) : null;
  const videoSrc = videoId ? `https://www.youtube.com/embed/${videoId}` : "";

  const calculateDuration = () => {
    if (webinar && webinar.fechaInicio && webinar.fechaFin) {
      const start = new Date(webinar.fechaInicio);
      const end = new Date(webinar.fechaFin);
      const duration = (end - start) / (1000 * 60 * 60); // Difference in hours
      return Math.floor(duration);
    }
    return null;
  };

  const formatDateAndTime = (fechaInicio) => {
    const date = new Date(fechaInicio);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = String(hours % 12 || 12).padStart(2, "0");

    return `${day}/${month}/${year} <br />   ${formattedHours}:${minutes} ${ampm}`;
  };

  const formattedDateAndTime = formatDateAndTime(webinar?.fechaInicio);

  // Detect if the user is on a mobile device
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream;
  };

  // Create WhatsApp link with the provided phone number
  const createWhatsAppLink = () => {
    const phoneNumber = "51991301271"; // Include country code (51 for Peru)
    const baseLink = isMobile()
      ? "whatsapp://send"
      : "https://web.whatsapp.com/send";
    const message = `Desea acceder a la constancia del webinar: ${webinar?.titulo}. ¡Gracias!`;
    return `${baseLink}?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
  };

  return (
    <>
      <Navbar />

      {loading ? (
        <div className="text-center">
          <img src="/assets/images/stemisp/gatitos.gif" alt="Cargando..." />
          <p>Cargando Webinar...</p>
        </div>
      ) : error ? (
        <div className="text-center">
          <p>
            Hubo un error al cargar el webinar. Por favor, intenta nuevamente
            más tarde.
          </p>
        </div>
      ) : webinar ? (
        <div className="mt-n6">
          {/*  <div
            style={{
              backgroundImage:
                'url("/assets/images/logostemis/fondoverde.svg")',
              backgroundRepeat: "no-repeat",

              backgroundPosition: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "90vh", // Ajusta la altura según sea necesario
              zIndex: "-1",
            }}
            className="background-image-container"
          ></div> */}
          <section>
            <img
              src={`${window.BACKEND_URL}uploads/${webinar.photo2}`}
              className="img-fluid rounded-2 w-100 "
              alt="Card image"
            />
          </section>
          <section className="d-flex justify-content-center container-sm">
            <div className="row align-items-center gx-2 mx-5">
              <div className="col-12 col-md-6 d-flex justify-content-center">
                <div className="mx-3 text-center">
                  <img
                    src={`${window.BACKEND_URL}uploads/${webinar.photo3}`}
                    alt=""
                    className="rounded-circle img-fluid"
                    style={{
                      border: "solid 3px #009688",
                      width: "7.5rem",
                      height: "7.5rem",
                      objectFit: "cover",
                    }}
                  />
                  <p className="mt-2 fs-6">
                    <b>{webinar.ponente}</b>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center">
                <div className="mx-3">
                  <div
                    ref={descripcionRef}
                    style={{
                      textAlign: "justify",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    dangerouslySetInnerHTML={{ __html: webinar.dponente }}
                  ></div>
                </div>
              </div>
            </div>
          </section>

          <div className="container">
            <hr />
            <div className="row">
              <div className="col-md-5">
                <h2 className="mb-4" style={{ color: "#454C5B" }}>
                  {webinar.titulo}
                </h2>
                {webinar.estado ? (
                  <>
                    <div
                      className="webinar-description"
                      style={{ textAlign: "justify" }}
                      dangerouslySetInnerHTML={{ __html: webinar.descripcion }}
                    ></div>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center">
                      <img
                        src="/assets/images/ree@4x.png"
                        alt="Reloj"
                        className="img-fluid w-50"
                        style={{ color: "#009688" }}
                      />
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <a
                        className="fs-5 btn btn-sm btn-outline-success"
                        href={`${webinar.linkformulario}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ir al Formulario
                      </a>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-7 d-flex ">
                {webinar.estado ? (
                  videoId ? (
                    <div>
                      {/* Mostrar en pantallas medianas y grandes */}
                      <div className="mt-6">
                        {/* Contenido del video que se mostrará en pantallas medianas y grandes */}
                        <div className="embed-responsive embed-responsive-16by9">
                          <iframe
                            className=""
                            data-aos-delay="1200"
                            style={{ width: "40rem", height: "25rem" }}
                            src={videoSrc}
                            title="YouTube video player"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>

                      <div className="d-md-none">
                        {/* Mostrar en pantallas pequeñas */}
                        <div className="embed-responsive embed-responsive-16by9 d-md-none mt-4">
                          <iframe
                            className="embed-responsive-item w-100"
                            src={videoSrc}
                            title="YouTube video player"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p>No se pudo cargar el video.</p>
                  )
                ) : (
                  <picture>
                    <img
                      src={`${window.BACKEND_URL}uploads/${webinar.photo}`}
                      alt="Reloj"
                      className="img-fluid rounded-3"
                      style={{
                        height: "auto",
                        backgroundColor: "#009688",
                      }}
                    />
                  </picture>
                )}
              </div>
              {webinar.estado ? (
                <div className="d-flex justify-content-center mt-5">
                  <a
                    className="fs-5 btn btn-sm btn-outline-success"
                    href={createWhatsAppLink()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CONSTANCIA
                  </a>
                </div>
              ) : null}
              <hr className="mt-2" />
            </div>
            <div className="row mt-5">
              <div className="col-md-3 mb-4">
                <div className="card text-center">
                  <div className="card-header d-flex justify-content-center">
                    <div
                      className="d-flex justify-content-center align-items-center rounded-circle"
                      style={{
                        width: "70px",
                        height: "70px",
                        backgroundColor: "#009688",
                        color: "#ffffff",
                      }}
                    >
                      <i className="bi bi-calendar2-date fs-1"></i>
                    </div>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title mt-3">Fecha</h5>
                    <p
                      className="card-text fs-5"
                      dangerouslySetInnerHTML={{
                        __html: formattedDateAndTime,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="card text-center">
                  <div className="card-header d-flex justify-content-center">
                    <div
                      className="d-flex justify-content-center align-items-center rounded-circle"
                      style={{
                        width: "70px",
                        height: "70px",
                        backgroundColor: "#009688",
                        color: "#ffffff",
                      }}
                    >
                      <i className="fa-regular fa-clock fs-1"></i>
                    </div>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title mt-3">Duración</h5>
                    <p className="card-text fs-5">
                      {calculateDuration()} horas
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="card text-center">
                  <div className="card-header d-flex justify-content-center">
                    <div
                      className="d-flex justify-content-center align-items-center rounded-circle"
                      style={{
                        width: "70px",
                        height: "70px",
                        backgroundColor: "#009688",
                        color: "#ffffff",
                      }}
                    >
                      <i className="fa-solid fa-money-bill fs-1"></i>
                    </div>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title mt-3">Certificacion</h5>
                    <p className="card-text fs-5">
                      {webinar.precio === 0
                        ? "Gratis"
                        : `S/. ${webinar.precio}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="card text-center">
                  <div className="card-header d-flex justify-content-center">
                    <div
                      className="d-flex justify-content-center align-items-center rounded-circle"
                      style={{
                        width: "70px",
                        height: "70px",
                        backgroundColor: "#009688",
                        color: "#ffffff",
                      }}
                    >
                      <i
                        className="fa-solid fa-desktop"
                        style={{ fontSize: "2rem" }}
                      ></i>
                    </div>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title mt-3">Metodologia</h5>
                    <p className="card-text fs-5">100% virtual</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Webinar no encontrado</p>
      )}

      <Footer />
    </>
  );
};

export default WebinarPage;
