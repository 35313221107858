import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function Baner() {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBaners = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}baner`, {
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "Content-Type": "application/json",
            "X-Rol": `${localStorage.getItem("Rol")}`, // Enviar el token en la cabecera Authorization
          },
        });
        setBanners(response.data);
      } catch (error) {
        console.error("Error al obtener los banners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBaners();
  }, []);

  return (
    <>
      <section className="overflow-hidden">
        <div className="container container-md">
          <div className="g-4 g-md-5 mt-n5">
            <div className="text-center">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center m-7">
                  <div className="text-center">
                    <div className="loader"></div>
                  </div>
                </div>
              ) : (
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    {banners.map((banner, index) => (
                      <div
                        className={`carousel-item ${
                          index === 0 ? "active" : ""
                        }`}
                        key={banner._id}
                      >
                        <Link to={`${banner?.descripcion}`}>
                          <img
                            src={`${window.BACKEND_URL}uploads/${banner.photo}`}
                            className="d-block w-100"
                            alt={`Slide ${index + 1}`}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Baner;
