import React from "react";

function Contactanos() {
  return (
    <>
      <section className="p-0 mt-3">
        <div className="text-center">
          <div className="d-flex justify-content-center align-items-center">
            <h5 className="text-azul-stemis">CONTACTANOS: </h5>
            <a
              href="https://wa.me/+51 991 301 271"
              className="icon-item mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-whatsapp fs-4 text-azul-stemis"></i>
            </a>
            <a
              href="https://www.tiktok.com/@stemis_?lang=es"
              className="icon-item mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-tiktok fs-4 text-azul-stemis"></i>
            </a>
            <a
              href="https://www.instagram.com/stemis_legaltech/"
              className="icon-item mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-instagram fs-4 text-azul-stemis"></i>
            </a>
            <a
              href="https://www.facebook.com/p/STemis-100087944859853/"
              className="icon-item mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-facebook fs-4 text-azul-stemis"></i>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactanos;
