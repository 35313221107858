import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer style={{ backgroundColor: "#1E1E1E" }}>
        <div className="container-fluid px-md-12 px-lg-12  p-0">
          <div className="row">
            <div className="col-sm-8 col-md-6 col-lg-4 mt-5 d-flex flex-column align-items-center">
              <img
                style={{ maxWidth: "50%" }}
                src="/assets/images/logostemis/logoblanoo.svg"
                alt="..."
                className="footer-brand img-fluid mt-6"
              />
              <p className="text-white" style={{ fontSize: "0.8em" }}>
                &#169; 2024 sTemis. Todos los derechos reservados
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-5 pt-7 ps-md-0 ps-lg-0 ms-3">
              <h4 style={{ color: "#4FB68F" }} className="fw-bold mb-3 p-0">
                Información de contacto
              </h4>
              <ul className="list-unstyled text-muted mb-6 me-n1 mb-md-8 mb-lg-0">
                <li className="mb-4">
                  <svg
                    style={{ fill: "#4FB68F", marginRight: "5px" }}
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                  </svg>
                  info@stemis.com.pe
                </li>
                <li className="mb-4">
                  <svg
                    style={{ fill: "#4FB68F", marginRight: "5px" }}
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                  </svg>
                  +51 991 301 271
                </li>
              </ul>
              <div className="row mt-3 mb-4">
                <a
                  href="https://www.facebook.com/profile.php?id=100087944859853"
                  className="col-2 col-md-2 col-sm-3"
                  style={{ fill: "#4FB68F" }}
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 
                48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 
                19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/stemis_legaltech/"
                  className="col-2 col-md-2 col-sm-3"
                  style={{ fill: "#4FB68F" }}
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 
                114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 
                33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 
                12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 
                36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 
                67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 
                9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 
                132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/stemis/"
                  className="col-2 col-md-2 col-sm-3"
                  style={{ fill: "#4FB68F" }}
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 
                480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 
                96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 
                54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-2 pt-7 ms-3 ms-md-0">
              <ul className="list-unstyled text-white mb-6 mb-md-8 mb-lg-0">
                {" "}
                <li className="mb-1">
                  <Link
                    to="/SimuladorHome"
                    style={{ color: "#FFFFFF", fontWeight: "bold" }}
                  >
                    Simulador
                  </Link>
                </li>
                <li
                  className="mb-1"
                  style={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  <Link to="/CursosHome" className="text-reset">
                    Cursos
                  </Link>
                </li>
                <li
                  className="mb-1"
                  style={{ color: "#FFFFFF", fontWeight: "bold" }}
                ></li>
                <li
                  className="mb-1"
                  style={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  <Link to="/blogs" className="text-reset">
                    Blog
                  </Link>
                </li>
                <li
                  className="mb-1"
                  style={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  <Link to="/normatividad" className="text-reset">
                    Normativa
                  </Link>
                </li>
                <li
                  className="mb-1"
                  style={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  <Link to="/Contact" className="text-reset">
                    Contactanos
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
