import React, { useState, useEffect, useMemo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from "sweetalert2";

const SesionAccordion = ({
  titulo,
  content,
  docente,
  youtubeLink,
  tipo,
  file1,
  file2,
  adicional = [],
  numeroModulo,
}) => {
  const [taskInput, setTaskInput] = useState("");
  const [forumInput, setForumInput] = useState("");
  const [forumResponses, setForumResponses] = useState({});
  const [fileInput, setFileInput] = useState(null);
  const [uploadedTaskFile, setUploadedTaskFile] = useState(null);

  const alumnoId = sessionStorage.getItem("alumnoId");

  const sendForumResponse = async (forumId, response) => {
    try {
      const res = await axios.post(
        `${window.BACKEND_URL}/forum/${forumId}/responses`,
        response
      );
      setForumResponses((prevResponses) => ({
        ...prevResponses,
        [forumId]: [...(prevResponses[forumId] || []), res.data],
      }));
      setForumInput(""); // Limpia el campo de entrada
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Error al enviar la respuesta",
      });
    }
  };

  const sendTask = async (adicionalId) => {
    try {
      const formData = new FormData();
      formData.append("task", taskInput);
      formData.append("adicionalId", adicionalId);
      if (fileInput) {
        formData.append("file", fileInput);
      }

      await axios.post(
        `${window.BACKEND_URL}tarea/${alumnoId}/enviar/${adicionalId}`,
        formData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setTaskInput("");
      setFileInput(null);
      Swal.fire({
        icon: "success",
        title: "Éxito",
        text: "Tarea enviada correctamente",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Error al enviar la tarea",
      });
    }
  };

  const fetchForumResponses = async (forumId) => {
    try {
      const res = await axios.get(
        `${window.BACKEND_URL}/forum/${forumId}/responses`
      );
      return res.data;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.response?.data?.message ||
          "Error al obtener respuestas del foro",
      });
      return [];
    }
  };

  const fetchTaskFile = async (taskId) => {
    try {
      const res = await axios.get(
        `${window.BACKEND_URL}tarea/${alumnoId}/obtener/${taskId}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.log("upss");
      return null;
    }
  };

  const tasks = useMemo(
    () => adicional.filter((item) => item.tipo === 2),
    [adicional]
  );

  useEffect(() => {
    const fetchData = async () => {
      for (const task of tasks) {
        try {
          const taskFile = await fetchTaskFile(task.AdicionalId, alumnoId);

          if (taskFile) {
            setUploadedTaskFile(taskFile);
          } else {
            console.warn(
              `No se recibió archivo para la tarea con ID: ${task.AdicionalId}`
            );
          }
        } catch (error) {
          console.error(
            `Error al obtener archivo para la tarea con ID: ${task.AdicionalId}`,
            error
          );
        }
      }
    };

    fetchData();
  }, [tasks, alumnoId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== "application/pdf") {
      Swal.fire({
        icon: "error",
        title: "Archivo no válido",
        text: "Solo se permiten archivos PDF.",
      });
      e.target.value = null; 
    } else {
      setFileInput(file);
    }
  };

  const extractFileName = (fileName) => {
    if (typeof fileName !== "string") {
      console.error("fileName debe ser una cadena. Valor recibido:", fileName);
      return "";
    }

    const parts = fileName.split("/");
    return parts[parts.length - 1];
  };

  const renderLink = () => {
    if (tipo === "Meet") {
      return (
        <a href={youtubeLink} target="_blank" rel="noopener noreferrer">
          Unirse a la Clase
        </a>
      );
    } else if (tipo === "Drive") {
      return (
        <a href={youtubeLink} target="_blank" rel="noopener noreferrer">
          Ver clase grabada
        </a>
      );
    } else if (tipo === "YouTube") {
      let videoId;

      if (youtubeLink.includes("youtu.be/")) {
        videoId = youtubeLink.split("youtu.be/")[1];
      } else if (youtubeLink.includes("v=")) {
        videoId = youtubeLink.split("v=")[1].split("&")[0]; // Extrae el ID si hay parámetros adicionales
      }

      if (videoId) {
        const embedLink = `https://www.youtube.com/embed/${videoId}`;
        return (
          <iframe
            width="560"
            height="315"
            src={embedLink}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      }
      return (
        <a href={youtubeLink} target="_blank" rel="noopener noreferrer">
          Video
        </a>
      );
    }
    return null;
  };

  const renderAdicional = () => {
    if (!Array.isArray(adicional)) return null;

    return adicional.map((item) => {
      if (item.tipo === 1) {
        return (
          <Accordion
            key={item.AdicionalId}
            defaultExpanded
            className="border border-success mt-2 rounded-4"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${item.AdicionalId}-content`}
              id={`panel-${item.AdicionalId}-header`}
            >
              <Typography className="bg-stemis" variant="h5">
                Material Adicional
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </Typography>
              {item.files &&
                item.files.map((file, index) => (
                  <div key={index} style={{ marginBottom: "8px" }}>
                    <a
                      href={`${window.BACKEND_URL}uploads/${file}`}
                      target="_blank"
                      className="fs-6 p-2 rounded-5 btn btn-success"
                      rel="noopener noreferrer"
                    >
                      {extractFileName(file)}{" "}
                      <i className="fa-solid fa-download"></i>
                    </a>
                  </div>
                ))}
            </AccordionDetails>
          </Accordion>
        );
      } else if (item.tipo === 2) {
        return (
          <Accordion
            key={item.AdicionalId}
            defaultExpanded
            className="border border-success mt-2 rounded-4"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${item.AdicionalId}-content`}
              id={`panel-${item.AdicionalId}-header`}
            >
              <Typography className="bg-stemis" variant="h5">
                Tarea
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </Typography>
              {item.files &&
                item.files.map((file, index) => (
                  <a
                    key={index}
                    href={`${window.BACKEND_URL}uploads/${file}`}
                    target="_blank"
                    className="fs-6 p-2 rounded-5 btn btn-outline-blue "
                  >
                    {extractFileName(file)}{" "}
                    <i className="fa-solid fa-download"></i>
                  </a>
                ))}
              {uploadedTaskFile ? (
                <div
                  style={{
                    width: "200px",
                    height: "auto",

                    borderLeft: "2px solid black",
                  }}
                  className="mt-2"
                >
                  <p className="fs-5 ms-2">Tarea enviada</p>
                  <p className="ms-2">Comentario: {uploadedTaskFile.task}</p>

                  <a
                    href={`${window.BACKEND_URL}uploads/${uploadedTaskFile.file}`}
                    target="_blank"
                    className="fs-6 ms-2 p-2 rounded-5 btn btn-outline-blue"
                    rel="noopener noreferrer"
                  >
                    {extractFileName(uploadedTaskFile.file)}{" "}
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendTask(item.AdicionalId);
                  }}
                >
                  <TextField
                    label="Comentario de la tarea"
                    value={taskInput}
                    onChange={(e) => setTaskInput(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="form-control"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    className="mt-2"
                  >
                    Enviar
                  </Button>
                </form>
              )}
            </AccordionDetails>
          </Accordion>
        );
      } else if (item.tipo === 3) {
        return (
          <Accordion
            key={item.AdicionalId}
            defaultExpanded
            className="border border-success mt-2 rounded-4"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${item.AdicionalId}-content`}
              id={`panel-${item.AdicionalId}-header`}
            >
              <Typography className="bg-stemis" variant="h5">
                Foro
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  sendForumResponse(item.AdicionalId, { content: forumInput }); // Llama a la función para enviar la respuesta del foro
                }}
              >
                <TextField
                  label="Responder en el foro"
                  value={forumInput}
                  onChange={(e) => setForumInput(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  Enviar
                </Button>
              </form>
              <div>
                {forumResponses[item.AdicionalId] &&
                  forumResponses[item.AdicionalId].map((response) => (
                    <div key={response.id} style={{ marginBottom: "16px" }}>
                      <Typography variant="subtitle2">
                        {response.userName} -{" "}
                        {new Date(response.createdAt).toLocaleDateString()}
                      </Typography>
                      <Typography variant="body2">
                        {response.content}
                      </Typography>
                    </div>
                  ))}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      }
      return null;
    });
  };

  return (
    <Accordion
      defaultExpanded
      className="shadow-custom border border-2 rounded-3 mt-5 bg-light "
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div>
          <Typography variant="h4" className="text-azul-stemis">
            Modulo {numeroModulo} : {titulo}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Docente: {docente}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <div
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>{" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {file1 && (
              <a
                href={`${window.BACKEND_URL}uploads/${file1}`}
                target="_blank"
                rel="noopener noreferrer"
                className="fs-6 p-2 rounded-5 btn btn-success"
                style={{ marginRight: "20px" }}
              >
                Diapositivas de la clase{" "}
                <i className="fa-solid fa-download"></i>
              </a>
            )}
            {file2 && (
              <a
                href={`${window.BACKEND_URL}uploads/${file2}`}
                target="_blank"
                rel="noopener noreferrer"
                className="fs-6 p-2 rounded-5 btn btn-success"
              >
                Material de la clase <i className="fa-solid fa-download"></i>
              </a>
            )}
          </div>
          <br />{" "}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {renderLink()}
          </div>
        </Typography>
        {renderAdicional()}
      </AccordionDetails>
    </Accordion>
  );
};

export default SesionAccordion;
