import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const BlogsDetalles = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [colaborador, setColaborador] = useState(null);

  const fetchBlog = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}blogposts/${id}`, {
        headers: {
          Authorization: `${localStorage.getItem("TokenPublic")}`,
          "Content-Type": "application/json",
          "X-Rol": `${localStorage.getItem("Rol")}`, // Enviar el token en la cabecera Authorization
        },
      });
      setBlog(response.data);
      if (response.data.colaborador_id) {
        fetchColaborador(response.data.colaborador_id);
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  const fetchColaborador = async (colaborador_id) => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}colaboradores/${colaborador_id}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "Content-Type": "application/json",
            "X-Rol": `${localStorage.getItem("Rol")}`, // Enviar el token en la cabecera Authorization
          },
        }
      );
      setColaborador(response.data);
    } catch (error) {
      console.error("Error fetching colaborador:", error);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, [id]);
  return (
    <>
      <Navbar />

      {blog ? (
        <div>
          <section
            data-speed=".8"
            className=""
            style={{
              backgroundImage: `url('${window.BACKEND_URL}uploads/${blog.imagen}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "25vw",
            }}
          ></section>
          <section className="pt-8 pt-md-11">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <h1 className="display-8 text-center">{blog.titulo}</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="pt-6 pt-md-8 pb-12">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                  <figure className="figure">
                    <img
                      className="figure-img img-fluid rounded"
                      src={`${window.BACKEND_URL}uploads/${blog.postcard}`}
                      alt="..."
                    />
                    <figcaption className="figure-caption text-start">
                      {blog.defoto}
                    </figcaption>
                  </figure>
                  <div className="col-12 col-md-10 col-lg-12 col-xl-12 py-6">
                    {colaborador && (
                      <div className="row align-items-center py-5 border-top border-bottom">
                        <div className="col-12 ms-n5">
                          <div className="row">
                            <div className="col-3 text-end">
                              <div className="me-2">
                                <img
                                  src={`${window.BACKEND_URL}uploads/${colaborador.photo}`}
                                  alt="Foto del colaborador"
                                  className="w-50 rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <h6 className="text-uppercase mb-0  mt-3">
                                {colaborador.namecol}
                                <br />
                                <span className="text-muted">Colaborador</span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <p style={{ color: "#259995", fontWeight: "bold" }}>
                    <a
                      className="btn btn-sm px-1 py-0"
                      style={{
                        color: "white",
                        backgroundColor: "#259995",
                      }}
                    >
                      Materia: {blog.tags}
                    </a>{" "}
                    <br />
                    {blog.sentencia} <br />
                    {blog.expediente} <br />
                    {blog.lugar}
                  </p>
                  <div
                    className="mb-0"
                    style={{ color: "#464D5B", textAlign: "justify" }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: blog.body }} />
                  </div>
                </div>
                <div className="col-12 text-center pt-8">
                  <a
                    style={{
                      backgroundColor: "#259995",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    className="btn"
                    target="_blank"
                    href={`${window.BACKEND_URL}uploads/${blog.PDFile}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>{" "}
                    Documento completo
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="text-center">
          <img src="/assets/images/stemisp/gatitos.gif" alt="Cargando..." />
          <p>Cargando Blog...</p>
        </div>
      )}

      <Footer />
    </>
  );
};

export default BlogsDetalles;
