import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Swal from "sweetalert2";
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    telefono: "",
    asunto: "",
    message: "",
    Notifications: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${window.BACKEND_URL}messages`,
        formData,
        {
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "Content-Type": "application/json",
            "X-Rol": `${localStorage.getItem("Rol")}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Mensaje Enviado exitosamente",
        showConfirmButton: false,
        timer: 1500,
      });
      window.history.back();
    } catch (error) {
      console.error("Error al guardar el mensaje:", error);
      Swal.fire({
        icon: "success",
        title: "Mensaje Enviado exitosamente",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <Navbar />
      <section className="container">
        <div className="row">
          <div className="col-lg-7 col-sm-12 col-xs-12">
            <img
              style={{ maxWidth: "100%" }}
              src="/assets/images/logostemis/contanc.png"
              alt=""
            />
          </div>
          <div className="col-lg-5 col-md-12 mb-8">
            <div className="card container col-12 bg-light rounded-5 shadow-custom">
              <h1 className="mb-0 mt-3 fw-bolder text-center  bg-stemis">
                Estamos para ayudarte
              </h1>
              <p className="mb-6 text-center text-azul-stemis ">
                Responderemos tus preguntas sin problema
              </p>
              <form className="contact-form mb-6" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Nombres
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Nombres"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Correo electrónico
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="correo@email.com"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="telefono" className="form-label">
                      Teléfono
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="telefono"
                      name="telefono"
                      placeholder="Teléfono"
                      value={formData.telefono}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="asunto" className="form-label">
                      Asunto
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="asunto"
                      name="asunto"
                      placeholder="Cursos sTemis"
                      value={formData.asunto}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Mensaje
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="3"
                    placeholder="Escribir mensaje..."
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="notifications"
                    name="Notifications"
                    checked={formData.Notifications}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="notifications">
                    Me gustaría recibir notificaciones sobre promociones,
                    productos y eventos de sTemis.
                  </label>
                </div>
                <div className="text-center">
                  <button
                    className="btn"
                    style={{ backgroundColor: "#259995", color: "white" }}
                    type="submit"
                  >
                    <i className="fa-solid fa-paper-plane me-3"></i>
                    Enviar un mensaje
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
